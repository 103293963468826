@tailwind base;
@tailwind components;
@tailwind utilities;

h1 {font-size: 41px; font-weight: 600;}
h2 {font-size: 28px; font-weight: 500;}
h3 {font-size: 26px; font-weight: 500;}
h4 {font-size: 22px; font-weight: 500;}
h5 {font-size: 18px; font-weight: 500;}
h6 {font-size: 15px; font-weight: 600;}


@import "./spinner.css";
@import "./checkbox.css";
@import "./radio.css";
@import "./table.css";
@import "./calendar.css";
@import "./fileUpload.css";
